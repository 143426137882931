import React from 'react'
import LineHeadline from 'components/LineHeadline'
import Inview from 'components/Inview'

const BlockTextColumnsItems = ({ content }) => {
  const { section, items } = content
  return (
    <section className='block-text-columns-items'>
      <div className='container'>
        <LineHeadline text={section} />
        <Inview className='main'>
          <div className='left fade-in up'>
            <Item content={items[0]} />
          </div>
          <div className='right fade-in up stagger-500'>
            <Item content={items[1]} />
            <Item content={items[2]} />
          </div>
        </Inview>
      </div>
    </section>
  )
}

export default BlockTextColumnsItems

const Item = ({ content }) => {
  const { title, excerpt } = content
  return (
    <div className='item'>
      <h5>{title}</h5>
      <p dangerouslySetInnerHTML={{ __html: excerpt }} />
    </div>
  )
}
