import React from 'react'
import Footer from 'components/Footer'
import Seo from 'components/Seo'
import BlockBanner from 'components/blocks/BlockBanner'
import BlockTextColumnsItems from 'components/blocks/BlockTextColumnsItems'
import { content, filterContentByLocale } from 'content/content.js'
import Inview from 'components/Inview'
import { useLocalization } from 'gatsby-theme-i18n'

const ContactPage = () => {
  const { locale } = useLocalization()
  const localeContent = filterContentByLocale(content, locale)
  const { contact, footer } = localeContent
  const { banner, textItems } = contact

  let seoContent = {
    title: {
      en: `Contact`,
      zh: `联系我们`,
    },
  }
  seoContent = filterContentByLocale(seoContent, locale)

  return (
    <div>
      <div className='page'>
        <Seo content={seoContent} />
        <div className='bg-gradient'>
          <BlockBanner content={banner} />
          <BlockTextColumnsItems content={textItems} />
          <Footer content={footer.contact} />
        </div>
      </div>
    </div>
  )
}

export default ContactPage
